<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.5714 36.2857C26.4725 36.2857 18.2857 44.4725 18.2857 54.5714V96.0355C23.6649 92.9238 29.9102 91.1429 36.5714 91.1429H219.429C226.09 91.1429 232.335 92.9238 237.714 96.0355V91.1429C237.714 81.0439 229.527 72.8571 219.429 72.8571H153.86C146.585 72.8571 139.609 69.9674 134.465 64.8235L108.605 38.9636C106.89 37.249 104.565 36.2857 102.14 36.2857H36.5714ZM237.714 127.714C237.714 117.615 229.527 109.429 219.429 109.429H36.5714C26.4725 109.429 18.2857 117.615 18.2857 127.714V200.857C18.2857 210.956 26.4725 219.143 36.5714 219.143H219.429C229.527 219.143 237.714 210.956 237.714 200.857V127.714ZM256 200.857C256 221.055 239.626 237.429 219.429 237.429H36.5714C16.3736 237.429 0 221.055 0 200.857V54.5714C0 34.3736 16.3736 18 36.5714 18H102.14C109.415 18 116.391 20.8898 121.535 26.0336L147.395 51.8936C149.11 53.6082 151.435 54.5714 153.86 54.5714H219.429C239.626 54.5714 256 70.945 256 91.1429V200.857Z"
      fill="currentColor"
    />
  </svg>
</template>
